.ordenPayTcBox {
    margin-top: 0% !important;
    width: 70rem !important;
    height: auto !important;
    padding: 60px 0px 0px 0px !important;
    gap: 58px !important;
    border-radius: 13px 13px 13px 13px !important;
    align-items: center !important;
    align-content: center !important;
}

.ordenPayTcTitle {
    gap: 0px !important;
    
    font-family: 'Roboto' !important;
    font-size: 34px !important;
    font-weight: 600 !important;
    line-height: 48px !important;
    text-align: center !important;
    color: #212C40 !important;

}

.ordenPayTCSubtitle {
    height: 23px !important;
    gap: 0px !important;
    
    font-family: 'Roboto' !important;
    font-size: 26px !important;
    font-weight: 500 !important;
    line-height: 32px !important;
    text-align: center !important;


}

.buttonCheck {
    width: 10% !important;
    height: 90px !important;
}

.buttonCheck .p-button-icon {
    font-size: 50px !important;

}

.buttonPay {
    height: auto !important;
    border-color: #19366C !important;
    background-color: #19366C !important;
    color: #ffffff !important;
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 1.2rem !important;
    line-height: 18.75px !important;
    text-align: center !important;
    padding: 1rem 2rem;
    margin-top: 1rem !important;
    transition: 0.2s !important;

}

.buttonPay:hover
{
    border: none;
    background-color: #D1432F !important;
    color: #ffffff !important;
}

.column Input {
    width: 100%;
    box-sizing: border-box;
    margin-top: 1.8%;
    border-radius: 10px;
}

.column label {
    font-size: 1.5vw;
    font-weight: 300;
    color: #1f3564;
    font-family: "Roboto", sans-serif;
    align-self: center;
}

.column h1 {
    font-size: 1vw;
    font-weight: 300;
    font-size: 1.3vw;
    align-items: center;
}

.column {
    display: flex;
    gap: 10vw;
    justify-content: space-between;
    text-align: center;
}

.forms {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    border-radius: 20px;
    padding: 3rem;
}

.OrdenTransferMessage {
    font-size: 2rem;
    font-weight: 600;
    color: #1f3564;
    font-family: "Roboto", sans-serif;
    align-self: left;
    margin-left: 60px;
}





/* Escritorio grande */
@media only screen and (min-width: 1800px) {}

/* Escritorio medio */
@media only screen and (min-width: 992px) and (max-width: 1199px) {}

@media only screen and (min-width: 965px) and (max-width: 984px) {}

@media only screen and (min-width: 768px) and (max-width: 964px) {}

/* Teléfonos */
@media only screen and (min-width: 521px) and (max-width: 767px) {}

/* Teléfonos */
@media only screen and (max-width: 430px) {
    .ordenPayTcBox {
        width: 385px !important;
        height: 650px !important;
        margin-top: 0px !important; 

    }

    .ordenPayTcTitle {
        font-size: 24px !important;
        font-weight: 800 !important;
        line-height: 48px !important;

    }

    .buttonCheck {
        width: 90px !important;
        height: 90px !important;
    }

    .ordenPayTCSubtitle {
        font-size: 22px !important;
        margin-top: 10px !important;
    }



    .buttonCheck .p-button-icon {
        font-size: 50px !important;
    }

    .buttonPay {
        width: 309px;
        height: 43px;
        padding: 16px 32px 16px 32px;
        gap: 10px;
        border-radius: 4px 0px 0px 0px;
        
        background-color: #19366C;

    }

    .column Input {
        width: 100%;
        box-sizing: border-box;
        margin-top: 1.8%;
        border-radius: 10px;
    }

    .column label {
        font-size: 1.3rem;
        font-weight: 300;
        color: #1f3564;
        font-family: "Roboto", sans-serif;
        align-self: center;
    }

    .column h1 {
        font-size: 1.3rem;
        font-weight: 300;
        align-items: center;
    }

    .column {
        display: flex;
        gap: 10vw;
        justify-content: space-between;
        text-align: center;
    }

    .forms {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        border-radius: 20px;
        padding: 3rem;
    }

    .OrdenTransferMessage {
        font-size: 1.7rem;
        font-weight: 600;
        color: #1f3564;
        font-family: "Roboto", sans-serif;
        align-self: left;
        margin-bottom: 2rem;
        margin-left: 0.6rem;
    }

    .backgroundIcon {
        position: relative; 
        z-index: 1; 
        color: #1F3564; 
        scale: 0.9;
      }

    .backgroundIcon::before {
        content: "\e908"; 
        font-family: 'PrimeIcons'; 
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(10%, 0%);
        font-size: 10rem; 
        color: #1F3564;
        opacity: 0.5; 
        z-index: 0; 
        pointer-events: none; 
      }

}