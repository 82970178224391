.homepage {
    text-align: center;
    padding: 20px;
}

h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    margin: 3rem 0;
    color: #19366C;
}

.conferenceName{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #19366C;
    margin-top: 3rem;
}

.congress-logo {
    width: 10rem;
    height: auto;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  
}
.grid-item {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s;
}

.grid-item:hover {
    transform: scale(1.05) !important;
    cursor: pointer;
}

.congress-image {
    width: 100%;
    height: auto;

}

@media (max-width: 768px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
}
