.ordenTransferBox {
    margin-top: 0% !important;
    width: 70rem !important;
    height: auto !important;
    padding: 60px 0px 0px 0px !important;
    gap: 58px !important;
    border-radius: 13px 13px 13px 13px !important;
    align-items: center !important;
    align-content: center !important;
}

.ordenTransferTitle {
    gap: 0px !important;
    align-self: center !important;
    font-family: 'Roboto' !important;
    font-size: 34px !important;
    font-weight: 600 !important;
    line-height: 48px !important;
    text-align: center !important;
    color: #1f3564 !important;
    margin-top: -80px !important;
}


.ordenTransferSubtitle {
    height: 23px !important;
    gap: 0px !important;
    font-family: 'Roboto' !important;
    font-size: 26px !important;
    font-weight: 500 !important;
    line-height: 32px !important;
    text-align: center !important;

}

.ordenTransferFileUpload {
    width: 90% !important;
    border-radius: 12px !important;
    font-family: 'Roboto' !important;
    margin-left: 50px !important;
    margin-top: 50px !important;
}

.ordenTransferInfo {
    gap: 0px !important;
    font-family: 'Roboto' !important;
    font-size: 1.rem !important;
    margin-left: 60px !important;
}

.column Input {
    width: 100%;
    box-sizing: border-box;
    margin-top: 1.8%;
    border-radius: 10px;
}

.column label {
    font-size: 1.5vw;
    font-weight: 300;
    color: #1f3564;
    font-family: "Roboto", sans-serif;
    align-self: center;
}

.column h1 {
    font-size: 1vw;
    font-weight: 300;
    font-size: 1.3vw;
    align-items: center;
}

.column {
    display: flex;
    gap: 10vw;
    justify-content: space-between;
    text-align: center;
}

.forms {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    border-radius: 20px;
    padding: 3rem;
}

.OrdenTransferMessage {
    font-size: 2rem;
    font-weight: 600;
    color: #1f3564;
    font-family: "Roboto", sans-serif;
    align-self: left;
    margin-left: 60px;
}

.p-fileupload-buttonbar{
    border: none;
}   
.p-fileupload-buttonbar .p-button{
    background-color: #1F3564;
    color: white;
    border: none;
    border-radius: 3px;
    transition: 0.25s;
}   
.p-fileupload-buttonbar .p-button:hover{
    background-color: #D1432F;
    color: white;
    border: none
}   






/* Escritorio grande */
@media only screen and (min-width: 1800px) {}

/* Escritorio medio */
@media only screen and (min-width: 992px) and (max-width: 1199px) {}

@media only screen and (min-width: 965px) and (max-width: 984px) {}

@media only screen and (min-width: 768px) and (max-width: 964px) {}

/* Teléfonos */
@media only screen and (min-width: 521px) and (max-width: 767px) {}

/* Teléfonos */
/* Teléfonos */
@media only screen and (max-width: 430px) {
    .ordenTransferBox {
        width: 385px !important;
        height: auto !important;
        margin-top: 0px !important;

    }

    .ordenTransferTitle {
        font-size: 24px !important;
        font-weight: 800 !important;
        line-height: 48px !important;

    }

    .ordenApprovedButtonCheck {
        width: 90px !important;
        height: 90px !important;
        margin-left: 140px !important;
    }

    .ordenTransferSubtitle {
        font-size: 22px !important;
        margin-top: 10px !important;
    }

    .ordenTransferFileUpload {
        width: 100% !important;
        height: 10% !important;
        margin-left: 0px !important;
        margin-top: 0px !important;
    }

    .textUpload {
        height: 10% !important;
    }

    .ordenTransferInfo {
        gap: 0px !important;
        font-family: 'Roboto' !important;
        font-size: 1.3rem !important;
        margin-left: 10px !important;
    }

    .column Input {
        width: 100%;
        box-sizing: border-box;
        margin-top: 1.8%;
        border-radius: 10px;
    }

    .column label {
        font-size: 1.3rem;
        font-weight: 300;
        color: #1f3564;
        font-family: "Roboto", sans-serif;
        align-self: center;
    }

    .column h1 {
        font-size: 1.3rem;
        font-weight: 300;
        align-items: center;
    }

    .column {
        display: flex;
        gap: 10vw;
        justify-content: space-between;
        text-align: center;
    }

    .forms {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        border-radius: 20px;
        padding: 3rem;
    }

    .OrdenTransferMessage {
        font-size: 1.7rem;
        font-weight: 600;
        color: #1f3564;
        font-family: "Roboto", sans-serif;
        align-self: left;
        margin-bottom: 2rem;
        margin-left: 0.6rem;
    }

    .backgroundIcon {
        position: relative; 
        z-index: 1; 
        color: #1F3564; 
        scale: 0.9;
      }

    .backgroundIcon::before {
        content: "\e908"; 
        font-family: 'PrimeIcons'; 
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(10%, 0%);
        font-size: 10rem; 
        color: #1F3564;
        opacity: 0.5; 
        z-index: 0; 
        pointer-events: none; 
      }


}