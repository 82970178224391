.bodyContent {
    align-items: center !important;
    margin-top: 100px !important;
    width: 1122px !important;
    height: 617px !important;
    border-radius: 13px !important;
    gap: 48px !important;
    background-color: #ffffff !important;
    padding: 10px !important;
    padding-top: 0px !important;
}

.homeContentHeader {
    width: 110% !important;
    height: 204px !important;
    margin-left: -1% !important;
    top: 2px !important;
    padding: 16px !important;
    gap: 20px !important;
    background-color: #19366C !important;
    text-align: center !important;
}

.homeContentTitle {
    height: 34px !important;
    font-family: 'Roboto' !important;
    font-weight: 600;
    font-size: 48px !important;
    line-height: 48px !important;
    margin-top: 57px !important;
    color: #FFFFFF !important;
}

.homeTitle {
    width: 486px !important;
    height: 28px !important;
    gap: 0px !important;
    
    font-family: 'Roboto' !important;
    line-height: 40px !important;
    font-weight: 600 !important;
    font-size: 40px !important;
    color: #212C40 !important;
}

.homeSubtitleContent {
    width: 486px !important;
    height: 11px !important;
    font-family: 'Roboto' !important;
    line-height: 40px !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    color: #212C40 !important;
}

.priceText {
    font-family: 'Roboto' !important;
    line-height: 40px !important;
    font-weight: 400 !important;
    font-size: 30px !important;
    margin-left: 100px !important;
    height: 28px !important;
    color: #212C40 !important;
}

.less {
    height: 31px !important;
    font-family: 'Roboto' !important;
    color: #212C40 !important;
    background-color: #DDE3ED !important;
    border: 1px solid #DDE3ED !important;
    margin-left: 10px !important;
}

.countParticipants {
    font-family: 'Roboto' !important;
    line-height: 40px !important;
    font-weight: 400 !important;
    font-size: 30px !important;
    margin-left: 10px !important;
    height: 28px !important;
    color: #212C40 !important;
}

.plus {
    height: 31px !important;
    font-family: 'Roboto' !important;
    color: #212C40 !important;
    background-color: #DDE3ED !important;
    border: 1px solid #DDE3ED !important;
    margin-left: 10px !important;
}

.bodyContent h2 {
    font-family: 'Roboto' !important;
    line-height: 32px !important;
    font-weight: 400 !important;
    font-size: 22.4px !important;
    width: 486px !important;
    height: 16px !important;
    color: #212C40 !important;

}

.grupalDiscuountInformation {
    font-family: 'Roboto' !important;
    line-height: 28px !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    width: 486px !important;
    height: 16px !important;
    color: #19366C !important;
}

.grupalDiscuountPrice {
    font-family: 'Roboto' !important;
    line-height: 28px !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    width: 486px !important;
    height: 16px !important;
    color: #19366C !important;
}

.totalPayment {
    width: 458px !important;
    height: 331px !important;
    padding: 0px, 0px, 0px, 0px !important;
    gap: 41px !important;
    border-radius: 6px !important;
    background-color: #F2F4F8 !important;
    margin-left: 50px !important;
}

.totalpaymentInfo {
    margin-top: 60px !important;
}

.count {
    font-family: 'Roboto' !important;
    line-height: 18px !important;
    font-weight: 400 !important;
    font-size: 24px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    color: #212C40 !important;
    background-color: #EBEEF4 !important;
    border: 1px solid #DDE3ED !important;
    border-radius: 4px !important;
}

.discount {
    font-family: 'Roboto' !important;
    line-height: 18px !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    margin-top: 60px !important;
}

.discountText {
    font-family: 'Roboto' !important;
    line-height: 40px !important;
    font-weight: 400 !important;
    font-size: 15px !important;
}

.subTotal {
    font-family: 'Roboto' !important;
    line-height: 18px !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    margin-left: -30px !important;
    width: 40% !important;
}

.paymentInfoDiscount {
    margin-left: -15px !important;
    height: 331px !important;
    padding: 32px, 20px, 32px, 20px !important;
    gap: 41px !important;
    font-family: 'Roboto' !important;
    font-size: 16px !important;
}

.descriptionConference {
    font-family: 'Roboto' !important;
    line-height: 24px !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    width: 489px !important;
    height: 227px !important;
    color: #666666 !important;
}

.linePaymentInfo {
    width: 369px !important;
    border: 1px solid #E4E9F1 !important;
    margin-top: 30px !important;
    margin-left: 30px !important;
}

.totalInfo {
    margin-left: 40px !important;
    font-family: 'Roboto' !important;
    line-height: 40px !important;
    font-weight: 400 !important;
    font-size: 25px !important;
    margin-top: 30px !important;
    height: 28px !important;
    color: #212C40 !important;
}

.h1text {
    margin-left: -30px !important;
    font-family: 'Roboto' !important;
    line-height: 40px !important;
    font-weight: 400 !important;
    font-size: 25px !important;
    margin-top: 30px !important;
    width: 55% !important;
    color: #212C40 !important;
}

.paymentInfoButton {
    width: 404px !important;
    height: 43px !important;
    border-radius: 4px !important;
    background-color: #19366C !important;
    margin-left: 10px !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .descriptionConference {
        width: 540px !important;
    }
}

@media only screen and (min-width: 965px) and (max-width: 984px) {
    .bodyContent {
        align-items: center !important;
        margin-top: 40px !important;
        margin-left: -100px !important;
        width: 800px !important;
        height: 700px !important;
        border-radius: 13px !important;
        gap: 48px !important;
        background-color: #ffffff !important;
        padding: 10px !important;
        padding-top: 0px !important;
    }

    .homeContentHeader {
        width: 110% !important;
        height: 144px !important;
        margin-left: -1% !important;
        top: 2px !important;
        padding: 16px !important;
        padding-top: 1px !important;
        gap: 20px !important;
        background-color: #19366C !important;
        text-align: center !important;
    }

    .homeContentTitle {
        height: 34px !important;
        font-family: 'Roboto' !important;
        font-weight: 600;
        font-size: 48px !important;
        line-height: 48px !important;
        margin-top: 57px !important;
        color: #FFFFFF !important;
    }

    .homeTitle {
        height: 28px !important;
        gap: 0px !important;
        
        font-family: 'Roboto' !important;
        line-height: 28px !important;
        font-weight: 600 !important;
        font-size: 40px !important;
        color: #212C40 !important;
    }

    .homeSubtitleContent {
        height: 11px !important;
        font-family: 'Roboto' !important;
        line-height: 40px !important;
        font-weight: 300 !important;
        font-size: 16px !important;
        color: #212C40 !important;
    }

    .priceText {
        font-family: 'Roboto' !important;
        line-height: 26px !important;
        font-weight: 400 !important;
        font-size: 26px !important;
        margin-left: 50px !important;
        height: 28px !important;
        color: #212C40 !important;
    }

    .less {
        height: 31px !important;
        font-family: 'Roboto' !important;
        color: #212C40 !important;
        background-color: #DDE3ED !important;
        border: 1px solid #DDE3ED !important;
        margin-left: 10px !important;
    }

    .countParticipants {
        font-family: 'Roboto' !important;
        line-height: 26px !important;
        font-weight: 400 !important;
        font-size: 26px !important;
        margin-left: 10px !important;
        height: 28px !important;
        color: #212C40 !important;
    }

    .plus {
        height: 31px !important;
        font-family: 'Roboto' !important;
        color: #212C40 !important;
        background-color: #DDE3ED !important;
        border: 1px solid #DDE3ED !important;
        margin-left: 10px !important;
    }

    .bodyContent h2 {
        font-family: 'Roboto' !important;
        line-height: 32px !important;
        font-weight: 400 !important;
        font-size: 22.4px !important;
        /* width: 486px !important; */
        height: 16px !important;
        color: #212C40 !important;

    }

    .grupalDiscuountInformation {
        font-family: 'Roboto' !important;
        line-height: 28px !important;
        font-weight: 400 !important;
        font-size: 18px !important;
        /* width: 486px !important; */
        height: 16px !important;
        color: #19366C !important;
    }

    .grupalDiscuountPrice {
        font-family: 'Roboto' !important;
        line-height: 28px !important;
        font-weight: 500 !important;
        font-size: 18px !important;
        /* width: 486px !important; */
        height: 16px !important;
        color: #19366C !important;
    }

    .descriptionConference {
        width: 380px !important;
        font-family: 'Roboto' !important;
        line-height: 24px !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        height: 227px !important;
        color: #666666 !important;
        text-align: justify !important;
    }

    .totalPayment {
        height: 331px !important;
        padding: 0px, 0px, 0px, 0px !important;
        gap: 41px !important;
        border-radius: 6px !important;
        background-color: #F2F4F8 !important;
        margin-left: 22px !important;
        width: 360px !important;
    }

    .totalpaymentInfo {
        margin-top: 60px !important;
    }

    .count {
        font-family: 'Roboto' !important;
        line-height: 18px !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        color: #212C40 !important;
        background-color: #8faff0 !important;
        border: 1px solid #DDE3ED !important;
        border-radius: 4px !important;
    }

    .discount {
        font-family: 'Roboto' !important;
        line-height: 18px !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        margin-top: 60px !important;
    }

    .discountText {
        font-family: 'Roboto' !important;
        line-height: 40px !important;
        font-weight: 400 !important;
        font-size: 12px !important;
    }

    .subTotal {
        font-family: 'Roboto' !important;
        line-height: 18px !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        margin-left: -30px !important;
        width: 40% !important;
    }

    .paymentInfoDiscount {
        margin-left: -15px !important;
        height: 331px !important;
        padding: 32px, 20px, 32px, 20px !important;
        gap: 41px !important;
        font-family: 'Roboto' !important;
        font-size: 16px !important;
    }



    .linePaymentInfo {
        width: 320px !important;
        border: 1px solid #E4E9F1 !important;
        margin-top: 30px !important;
        margin-left: 20px !important;
    }

    .totalInfo {
        margin-left: 65px !important;
        font-family: 'Roboto' !important;
        line-height: 40px !important;
        font-weight: 400 !important;
        font-size: 20px !important;
        margin-top: 30px !important;
        height: 28px !important;
        color: #212C40 !important;
    }

    .h1text {
        margin-left: -30px !important;
        font-family: 'Roboto' !important;
        line-height: 40px !important;
        font-weight: 400 !important;
        font-size: 20px !important;
        margin-top: 30px !important;
        width: 55% !important;
        color: #212C40 !important;
    }

    .paymentInfoButton {
        width: 318px !important;
        height: 43px !important;
        border-radius: 4px !important;
        background-color: #19366C !important;
        margin-left: 10px !important;
    }
}


@media only screen and (min-width: 768px) and (max-width: 964px) {
    .bodyContent {
        margin-left: 380px !important;
        margin-top: 100px !important;
        width: 1122px !important;
        height: 617px !important;
        border-radius: 13px !important;
        padding: 60px !important;
        gap: 48px !important;
        background-color: #ffffff !important;
    }

    .homeContentHeader {
        width: 110% !important;
        height: 204px !important;
        margin-left: -1% !important;
        top: 2px !important;
        padding: 16px !important;
        gap: 20px !important;
        background-color: #19366C !important;
    }

    .homeContentTitle {
        /* width: 181px !important; */
        height: 34px !important;
        font-family: 'Roboto' !important;
        font-weight: 600;
        font-size: 48px !important;
        line-height: 48px !important;
        margin-left: 500px !important;
        margin-top: 57px !important;
        color: #FFFFFF !important;
    }

    .homeTitle {
        width: 486px !important;
        height: 28px !important;
        gap: 0px !important;
        
        font-family: 'Roboto' !important;
        line-height: 40px !important;
        font-weight: 600 !important;
        font-size: 40px !important;
        color: #212C40 !important;
    }

    .homeSubtitleContent {
        width: 486px !important;
        height: 11px !important;
        font-family: 'Roboto' !important;
        line-height: 40px !important;
        font-weight: 300 !important;
        font-size: 16px !important;
        color: #212C40 !important;
    }

    .priceText {
        font-family: 'Roboto' !important;
        line-height: 40px !important;
        font-weight: 400 !important;
        font-size: 30px !important;
        margin-left: 50px !important;
        height: 28px !important;
        color: #212C40 !important;
    }

    .less {
        height: 31px !important;
        font-family: 'Roboto' !important;
        color: #212C40 !important;
        background-color: #DDE3ED !important;
        border: 1px solid #DDE3ED !important;
        margin-left: 10px !important;
    }

    .countParticipants {
        font-family: 'Roboto' !important;
        line-height: 40px !important;
        font-weight: 400 !important;
        font-size: 30px !important;
        margin-left: 10px !important;
        height: 28px !important;
        color: #212C40 !important;
    }

    .plus {
        height: 31px !important;
        font-family: 'Roboto' !important;
        color: #212C40 !important;
        background-color: #DDE3ED !important;
        border: 1px solid #DDE3ED !important;
        margin-left: 10px !important;
    }

    .bodyContent h2 {
        font-family: 'Roboto' !important;
        line-height: 32px !important;
        font-weight: 400 !important;
        font-size: 22.4px !important;
        width: 486px !important;
        height: 16px !important;
        color: #212C40 !important;

    }

    .grupalDiscuountInformation {
        font-family: 'Roboto' !important;
        line-height: 28px !important;
        font-weight: 400 !important;
        font-size: 18px !important;
        width: 486px !important;
        height: 16px !important;
        color: #19366C !important;
    }

    .grupalDiscuountPrice {
        font-family: 'Roboto' !important;
        line-height: 28px !important;
        font-weight: 500 !important;
        font-size: 18px !important;
        width: 486px !important;
        height: 16px !important;
        color: #19366C !important;
    }

    .totalPayment {
        width: 444px !important;
        height: 331px !important;
        padding: 32px, 20px, 32px, 20px !important;
        gap: 41px !important;
        border-radius: 6px !important;
        background-color: #F2F4F8 !important;
        margin-left: 50px !important;
    }

    .totalpaymentInfo {
        margin-top: 60px !important;
    }

    .paymentInfoDiscount {
        width: 444px !important;
        height: 331px !important;
        padding: 32px, 20px, 32px, 20px !important;
        gap: 41px !important;
        font-family: 'Roboto' !important;
        font-size: 16px !important;
    }

    .descriptionConference {
        font-family: 'Roboto' !important;
        line-height: 24px !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        width: 489px !important;
        height: 227px !important;
        color: #666666 !important;

    }

    .count {
        font-family: 'Roboto' !important;
        line-height: 18px !important;
        font-weight: 400 !important;
        font-size: 24px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        color: #212C40 !important;
        background-color: #EBEEF4 !important;
        border: 1px solid #DDE3ED !important;
        border-radius: 4px !important;
    }

    .total {
        font-family: 'Roboto' !important;
        line-height: 18px !important;
        font-weight: 400 !important;
        font-size: 24px !important;
    }

    .linePaymentInfo {
        width: 369px !important;
        border: 1px solid #E4E9F1 !important;
        margin-top: 30px !important;
        margin-left: 30px !important;
    }

    .totalInfo {
        margin-left: 40px !important;
    }

    .h1text {
        margin-left: -30px !important;
        font-family: 'Roboto' !important;
        line-height: 40px !important;
        font-weight: 400 !important;
        font-size: 25px !important;
        margin-top: 30px !important;
        width: 55% !important;
        /* height: 28px !important; */
        color: #212C40 !important;
    }

    .paymentInfoButton {
        width: 404px !important;
        height: 43px !important;
        border-radius: 4px !important;
        background-color: #19366C !important;
        margin-left: 10px !important;
    }

    .discountText {
        margin-top: 60px !important;
        margin-left: -100px !important;
        margin-bottom: 0px !important;
        font-family: 'Roboto' !important;
        line-height: 40px !important;
        font-weight: 400 !important;
        font-size: 25px !important;
    }
}

/* Teléfonos */
@media only screen and (max-width: 430px) {

    .homeContentHeader {
        width: 100% !important;
        height: 100px !important;
        margin-left: 0% !important;
        top: 2px !important;
        padding: 16px !important;
        padding-top: 0px !important;
        gap: 20px !important;
        background-color: #19366C !important;

    }

    .homeContentTitle {
        height: 34px !important;
        font-family: 'Roboto' !important;
        font-weight: 600;
        font-size: 28px !important;
        line-height: 48px !important;
        color: #FFFFFF !important;
        padding-top: 22px !important;
        margin-top: 0px !important;
        text-align: center !important;
        justify-content: center !important;
    }

    .bodyContent {
        margin-left: 10px !important;
        margin-top: 0px !important;
        width: 380px !important;
        height: 1050px !important;
        border-radius: 13px !important;
        padding: 0px !important;
        gap: 48px !important;
        background-color: #ffffff !important;
    }

    .homeTitle {
        width: 486px !important;
        height: 28px !important;
        gap: 0px !important;
        
        font-family: 'Roboto' !important;
        line-height: 40px !important;
        font-weight: 600 !important;
        font-size: 30px !important;
        color: #212C40 !important;
    }

    .homeSubtitleContent {
        width: 486px !important;
        height: 11px !important;
        font-family: 'Roboto' !important;
        line-height: 40px !important;
        font-weight: 300 !important;
        font-size: 16px !important;
        color: #212C40 !important;
    }

    .priceText {
        font-family: 'Roboto' !important;
        line-height: 20px !important;
        font-weight: 400 !important;
        font-size: 20px !important;
        margin-left: 30px !important;
        height: 28px !important;
        color: #212C40 !important;
    }

    .less {
        height: 31px !important;
        font-family: 'Roboto' !important;
        color: #212C40 !important;
        background-color: #DDE3ED !important;
        border: 1px solid #DDE3ED !important;
        margin-left: 30px !important;
    }

    .countParticipants {
        font-family: 'Roboto' !important;
        line-height: 40px !important;
        font-weight: 400 !important;
        font-size: 20px !important;
        margin-left: 10px !important;
        height: 28px !important;
        color: #212C40 !important;
    }

    .plus {
        height: 31px !important;
        font-family: 'Roboto' !important;
        color: #212C40 !important;
        background-color: #DDE3ED !important;
        border: 1px solid #DDE3ED !important;
        margin-left: 10px !important;
    }

    .bodyContent h2 {
        font-family: 'Roboto' !important;
        line-height: 32px !important;
        font-weight: 400 !important;
        font-size: 22.4px !important;
        width: 486px !important;
        height: 16px !important;
        color: #212C40 !important;

    }

    .grupalDiscuountInformation {
        font-family: 'Roboto' !important;
        line-height: 28px !important;
        font-weight: 400 !important;
        font-size: 18px !important;
        width: 330px !important;
        height: 16px !important;
        color: #19366C !important;
        text-align: justify !important;
    }

    .grupalDiscuountPrice {
        font-family: 'Roboto' !important;
        line-height: 28px !important;
        font-weight: 500 !important;
        font-size: 18px !important;
        width: 486px !important;
        height: 16px !important;
        color: #19366C !important;
    }

    .totalPayment {
        width: 340px !important;
        height: 331px !important;
        padding: 32px, 20px, 32px, 20px !important;
        gap: 41px !important;
        border-radius: 6px !important;
        background-color: #F2F4F8 !important;
        margin-left: 10px !important;
    }

    .totalpaymentInfo {
        margin-top: 60px !important;
    }

    .paymentInfoDiscount {
        width: 444px !important;
        height: 331px !important;
        padding: 32px, 20px, 32px, 20px !important;
        gap: 41px !important;
        font-family: 'Roboto' !important;
        font-size: 16px !important;
    }

    .descriptionConference {
        font-family: 'Roboto' !important;
        line-height: 24px !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        width: 330px !important;
        height: 227px !important;
        color: #666666 !important;

    }

    .count {
        font-family: 'Roboto' !important;
        line-height: 18px !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        color: #212C40 !important;
        background-color: #EBEEF4 !important;
        border: 1px solid #DDE3ED !important;
        border-radius: 4px !important;
    }

    .discount {
        font-family: 'Roboto' !important;
        line-height: 18px !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        margin-top: -90px !important;
        margin-left: 60px !important;
        margin-bottom: -10;

    }

    .discountText {
        margin-bottom: 0px !important;
        font-family: 'Roboto' !important;
        line-height: 40px !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        margin-left: 50px !important;
    }

    .total {
        font-family: 'Roboto' !important;
        line-height: 18px !important;
        font-weight: 400 !important;
        font-size: 24px !important;

    }

    .subTotal {
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        margin-left: 50px !important;
        margin-top: -100px !important;
    }
    .subTotalWithDiscount{
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        margin-left: 190px !important;
        margin-top: -106px !important;
    }

    .linePaymentInfo {
        width: 310px !important;
        border: 2px solid #E4E9F1 !important;
        border-bottom: none !important;
        border-left: none !important;
        border-right: none !important;
        margin-top: -80px !important;
        margin-left: 20px !important;

    }

    .totalInfo {
        margin-left: 65px !important;
        margin-top: -60px !important;
        font-family: 'Roboto' !important;
        line-height: 40px !important;
        font-weight: 600 !important;
        font-size: 15px !important;
    }

    .h1text {
        font-family: 'Roboto' !important;
        line-height: 40px !important;
        font-weight: 400 !important;
        font-size: 15px !important;
        margin-top: -60px !important;
        margin-left: 125px !important;
        width: 55% !important;
        color: #212C40 !important;
    }

    .paymentInfoButton {
        width: 300px !important;
        height: 43px !important;
        border-radius: 4px !important;
        background-color: #19366C !important;
        margin-left: 10px !important;
    }


}

.descriptionConference {
    text-align: justify !important;
}