.changePasswordBody {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 98vh !important;
}

.changePasswordCard {
    width: 441px !important;
    height: 590px !important;
    border-radius: 13px !important;
    padding: 40px !important;
    padding-left: 10px !important;
    gap: 32px !important;
    background-color: #FFFFFF !important;
    box-shadow: 0px 2px 6px 0px #19213D24 !important;
}

.changePasswordImage {
    object-fit: fill !important;
    width: 380px !important;
    height: 156px !important;
    margin-top: -30px !important;
    margin-bottom: 40px !important;
}

.changePasswordTitle {
    width: 366px !important;
    height: 61px !important;
    gap: 24px !important;
    
    margin-top: -10px !important;

}

.changePasswordTitleText {
    font-family: 'Roboto' !important;
    font-weight: 600 !important;
    font-size: 28px !important;
    line-height: 32px !important;
    width: 181px;
    height: 23px;
    gap: 0px;
    
    color: #212C40
}


.changePasswordemailTextBox {
    width: 390px !important;
    height: 46px !important;
    border-radius: 6px !important;
    border: 1px solid #FFFFFF !important;
    padding: 18px, 16px, 18px, 16px !important;
    gap: 10px !important;
    box-shadow: 0px 1px 4px #19213D14 !important;
    margin-left: -10px !important;

}

.changePasswordshowPassword {
    height: 46px !important;
    background-color: transparent !important;
    color: #212C40 !important;
    border: none !important;
    border-radius: 2px !important;
    border-left: none !important;
    padding: 18px, 16px, 18px, 16px !important;
    gap: 10px !important;
    margin-left: -40px !important;

}

.changePasswordButton {
    width: 180px !important;
    height: 43px !important;
    border-radius: 4px !important;
    padding: 16px, 32px, 16px, 32px !important;
    gap: 10px !important;
    background-color: #19366C !important;
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 18.75px !important;
    color: #FFFFFF !important;
    margin-left: 1rem !important;
    border: none;
    box-shadow: #19366C;
    transition: 0.2s !important;
}

.changePasswordButton:hover {
    background-color: #142c57 !important;
    border: none !important;
}

.changePasswordButtonBack {
    width: 180px !important;
    height: 43px !important;
    border-radius: 4px !important;
    padding: 16px, 32px, 16px, 32px !important;
    gap: 10px !important;
    background-color: #19366C !important;
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 18.75px !important;
    background-color: #8a8a8a !important;
    margin-left: 1rem !important;
    border: none !important;
    transition: 0.2s !important;
}

.changePasswordButtonBack:hover {

    background-color: #4d4d4d !important;
    border: none !important;
}
@media only screen and (max-width: 430px) {
    .changePasswordButton {
        width: 100% !important;
        margin-left: 0px !important;
        height: 43px !important;
        border-radius: 4px !important;
        padding: 16px, 32px, 16px, 32px !important;
        gap: 10px !important;
        background-color: #19366C !important;
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 18.75px !important;
        color: #FFFFFF !important;
    }
    
    .changePasswordButtonBack {
        width: 100% !important;
        /* margin-right: 6px !important; */
        height: 43px !important;
        border-radius: 4px !important;
        padding: 16px, 32px, 16px, 32px !important;
        gap: 10px !important;
        background-color: #2755A9 !important;
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 18.75px !important;
        color: #FFFFFF !important;
    }

}