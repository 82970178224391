.ordenPaymentInfoHeader {
    width: 102% !important;
    height: 110px !important;
    margin-left: -1% !important;
    top: 2px !important;
    padding: 16px !important;
    gap: 20px !important;
    background-color: #19366C !important;
    text-align: center !important;
}

.ordenPaymentInfoTitle {
    height: 34px !important;
    font-family: 'Roboto' !important;
    font-weight: 600;
    font-size: 48px !important;
    line-height: 48px !important;
    margin-left: 0px !important;
    margin-top: 12px !important;
    color: #FFFFFF !important;
}

.ordenPaymentInfoBox {
    width: 100% !important;
    margin-left: 1% !important;
    padding: 16px !important;
    gap: 20px !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.ordenPaymentInfoSpace {
    margin-top: -50px !important;
    width: 100% !important;
}



.ordenPaymentInfoBillInfoText {
    height: 11px !important;
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #666666 !important;
    margin-top: 50px !important;
    margin-bottom: 20px !important;
}

.ordenPaymentInfoBillInfoInput {
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #666666 !important;
    border: none !important;
    width: 100% !important;
    height: 50px !important;
    border-bottom: solid 2px #19213D14 !important;
    border-left: solid 2px #19213D14 !important;
    border-right: solid 2px #19213D14 !important;
    border-radius: 8px !important;
    display: block;
    /* Evita que se superponga al input */
    width: auto;
    /* Ajusta el tamaño al contenido */
    margin-bottom: 5px;
    /* Separa el label del input */
    position: relative;
    /* Asegura que no cubra nada */
    z-index: 1;
}

.ordenPaymentInfoBill {
    height: 28px !important;
    font-family: 'Roboto' !important;
    font-weight: 600;
    font-size: 40px !important;
    line-height: 40px !important;
    color: #212C40 !important;
}

.ordenPaymentInfoBillText {
    height: 28px !important;
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #666666 !important;
    text-align: justify !important;
}

.ordenPaymentInfoResumeBox {
    width: 100% !important;
    height: 22rem !important;
    padding: 32px, 12px, 32px, 12px !important;
    gap: 24px !important;
    border: 1px solid #D0D8E7 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    margin-top: 40px !important;
}

.ordenPaymentInfoParticipantCount {
    width: 13px !important;
    height: 15px !important;
    font-family: 'Roboto' !important;
    font-weight: 800;
    font-size: 20px !important;
    line-height: 18px !important;
    color: #212C40 !important;
    text-align: center !important;
    margin-left: 10px !important;
    margin-top: 20px !important;
}

.ordenPaymentInfoPriceLessDiscount {
    height: 15px !important;
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #212C40 !important;
    text-align: center !important;
    margin-left: -40px !important;
    margin-top: 20px !important;
}

.ordenPaymentInfoResult {
    height: 15px !important;
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 18px !important;
    line-height: 28px !important;
    color: #212C40 !important;
    text-align: center !important;
    margin-left: -40px !important;
    margin-top: 20px !important;
}

.ordenPaymentInfoLine {
    width: 80% !important;
    border: #E4E9F1 1px solid !important;
    margin-top: 40px !important;
    margin-left: 30px !important;
}

.ordenPaymentInfoTotalText {
    height: 15px !important;
    font-family: 'Roboto' !important;
    font-weight: 600;
    font-size: 19.2px !important;
    line-height: 24px !important;
    color: #212C40 !important;
    text-align: center !important;
    align-self: center !important;
    margin-top: 20px !important;
}

.ordenPaymentInfoDiscountInpunt {
    width: 80% !important;
    height: 51px !important;
    border-radius: 8px !important;
    border: 1px solid #F1F3F7 !important;
    background-color: #FFFFFF !important;

    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #6D758F !important;
    margin-top: 1.5rem !important;
}

.ordenPaymentInfoDiscountButton {
    width: 15%;
    height: 3.2rem !important;
    border-radius: 4px !important;
    padding: 1rem 1rem !important;
    gap: 3px !important;
    background-color: #6D758F !important;

    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #FFFFFF !important;
    border: none;
    transition: 0.25s;

}

.ordenPaymentInfoDiscountButton:hover {
    background-color: #D1432F !important;
    color: #FFFFFF !important;

}

.ordenPaymentInfoPaymentMethodBox {
    width: 90rem;
    height: max-content !important;
    padding: 32px, 12px, 32px, 12px !important;
    gap: 24px !important;
    border: 1px solid #D0D8E7 !important;
    background-color: #F2F4F8 !important;
    box-shadow: none !important;
    margin-top: 1rem !important;
    width: 100% !important;

}

.ordenPaymentInfoPaymentMethodHeader {

    font-family: 'Roboto' !important;
    font-weight: 600;
    font-size: 2.5rem !important;
    line-height: 40px !important;
    color: #212C40 !important;
    text-align: center !important;
}

.ordenPaymentInfoButtonSpace {
    margin-top: 0rem !important;
    margin-bottom: 2rem !important;
    text-align: center !important;
}

.ordenPaymentInfoButtonTC {
    width: max-content !important;
    background-color: #19366C !important;
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 18.75px !important;
    color: #FFFFFF !important;
    text-align: center !important;
    height: 3rem !important;
    margin-right: 2rem;
    transition: 0.25s;
}



.ordenPaymentInfoButtonTD {
    width: max-content !important;
    background-color: #FFFFFF !important;
    border: 1px solid #19366C !important;
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 18.75px !important;
    color: #19366C !important;
    text-align: center !important;
    height: 3rem !important;
    margin-right: 2rem;
    transition: 0.25s;
}

.ordenPaymentInfoButtonTC:hover {
    border: none !important;
    background-color: #D1432F !important;
    color: #FFFFFF !important;
}

.ordenPaymentInfoButtonTD:hover {
    border: none !important;
    background-color: #D1432F !important;
    color: #FFFFFF !important;
}

.ordenPaymentInfoPaymentMethodTypeBox {
    height: 263px !important;
    padding: 40px, 32px, 40px, 32px !important;
    gap: 28px !important;
    border: 1px solid #D7DDEA !important;
    background-color: #F2F4F8 !important;
    box-shadow: none !important;
    margin-top: 40px !important;
    width: 100% !important;
}

.ordenPaymentInfoPaymentMethodInfoTC {
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 17px !important;
    line-height: 18.75px !important;
    color: #212C40 !important;
}

.ordenPaymentInfoPaymentMethodInfoTCStrong {
    font-family: 'Roboto' !important;
    font-weight: 600;
    font-size: 17px !important;
    line-height: 18.75px !important;
    color: #212C40 !important;
}

.ordenPaymentInfoPaymentMethodInfoTCContact {

    color: #CC4430 !important;
}

.ordenPaymentInfoPaymentMethodContinueToPayment {
    padding: 1rem 2rem !important;
    background-color: #19366C !important;
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 1.2rem !important;
    line-height: 18.75px !important;
    color: #FFFFFF !important;
    width: 100% !important;
    margin-top: 2rem;
}

.ordenPaymentInfoPaymentMethodContinueToPayment:hover {
    background-color: #D1432F !important;
}

.discountText {
    margin-left: 50px !important;
    font-size: 12px !important;
}

/* Escritorio grande */
@media only screen and (min-width: 1800px) {


    .ordenPaymentInfoPaymentMethodHeader {
        font-size: 40px !important;
        margin-bottom: 10px !important;
        margin-left: 10px !important;
        text-align: center !important;
    }

    .ordenPaymentInfoButtonTC {
        width: 160px !important;
        padding: 0px, 0px, 0px, 0px !important;
        margin-left: 10px !important;
    }

    .ordenPaymentInfoButtonTD {
        width: 160px !important;
        padding: 0px, 0px, 0px, 0px !important;
        margin-left: 10px !important;
    }

    .ordenPaymentInfoPaymentMethodInfoTC {
        margin-top: 0px !important;
        font-size: 17px !important;
    }

    .ordenPaymentInfoPaymentMethodInfoTCStrong {
        font-size: 17px !important;
    }

    .ordenPaymentInfoPaymentMethodContinueToPayment {
        font-size: 12rem;
    }
}

/* Escritorio medio */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .ordenPaymentInfoBox {
        width: 110% !important;

    }


    .ordenPaymentInfoPaymentMethodHeader {
        font-size: 40px !important;
        margin-bottom: 10px !important;
        margin-left: 10px !important;
        text-align: center !important;
    }

    .ordenPaymentInfoButtonTC {
        width: 160px !important;
        padding: 0px, 0px, 0px, 0px !important;
        margin-left: 0px !important;
    }

    .ordenPaymentInfoButtonTD {
        width: 160px !important;
        padding: 0px, 0px, 0px, 0px !important;
        margin-left: 0px !important;
    }

    .ordenPaymentInfoPaymentMethodInfoTC {
        margin-top: -10px !important;
        font-size: 17px !important;
    }

    .ordenPaymentInfoPaymentMethodInfoTCStrong {
        font-size: 17px !important;
    }

    .ordenPaymentInfoPaymentMethodContinueToPayment {
        width: 450px !important;
        margin-top: 10px !important;
        margin-left: 25px !important;
    }

    .ordenPaymentInfoBillText {
        width: 96% !important;
    }

    .ordenPaymentInfoBillInfoInput {
        width: 96% !important;
    }

    .userFormDetailDropdown {
        width: 96% !important;

    }
}

@media only screen and (min-width: 965px) and (max-width: 984px) {


    .ordenPaymentInfoPaymentMethodHeader {
        font-size: 24px !important;
        margin-left: 10px !important;
        text-align: center !important;
    }

    .ordenPaymentInfoButtonTC {
        width: 160px !important;
        padding: 0px, 0px, 0px, 0px !important;
        margin-left: 10px !important;
    }

    .ordenPaymentInfoButtonTD {
        width: 160px !important;
        padding: 0px, 0px, 0px, 0px !important;
        margin-left: 10px !important;
    }

    .ordenPaymentInfoPaymentMethodInfoTC {
        margin-top: 0px !important;
        font-size: 14px !important;
    }

    .ordenPaymentInfoPaymentMethodInfoTCStrong {
        font-size: 14px !important;
    }

    .ordenPaymentInfoPaymentMethodContinueToPayment {
        width: 300px !important;
        margin-top: 0px !important;
        margin-left: 25px !important;
    }

}

@media only screen and (min-width: 768px) and (max-width: 964px) {
    .ordenPaymentInfoBox {
        width: 110% !important;

    }

    .ordenPaymentInfoPaymentMethodHeader {
        font-size: 40px !important;
        margin-bottom: 10px !important;
        margin-left: 10px !important;
        text-align: center !important;
    }

    .ordenPaymentInfoButtonTC {
        width: 160px !important;
        padding: 0px, 0px, 0px, 0px !important;
        margin-left: 0px !important;
    }

    .ordenPaymentInfoButtonTD {
        width: 160px !important;
        padding: 0px, 0px, 0px, 0px !important;
        margin-left: 0px !important;
    }

    .ordenPaymentInfoPaymentMethodInfoTC {
        margin-top: -10px !important;
        font-size: 14px !important;
    }

    .ordenPaymentInfoPaymentMethodInfoTCStrong {
        font-size: 14px !important;
    }

    .ordenPaymentInfoPaymentMethodContinueToPayment {
        width: 250px !important;
        margin-top: -10px !important;
        margin-left: 25px !important;
    }

    /* sss */
    .ordenPaymentInfoResumeBox {

        width: 380px !important;
        margin-left: 5px !important;
    }

    .ordenPaymentInfoPriceLessDiscount {
        font-size: 18px !important;
        margin-left: 0px !important;
        margin-top: 20px !important;
    }

    .ordenPaymentInfoResult {
        margin-top: 100px !important;
        margin-left: -300px !important;
    }

    .ordenPaymentInfoLine {
        width: 80% !important;
        margin-top: 10px !important;
    }

    .ordenPaymentInfoDiscountInpunt {
        width: 350px !important;
    }
}

/* Teléfonos */
@media only screen and (min-width: 521px) and (max-width: 767px) {
    .ordenPaymentInfoHeader {
        height: 95px !important;
    }

    .ordenPaymentInfoTitle {
        font-size: 34px !important;
    }

}

/* Teléfonos */
@media only screen and (max-width: 430px) {
    .ordenPaymentInfoHeader {
        height: 95px !important;
    }

    .ordenPaymentInfoDiscountButton {
        width: 100%;


    }

    .ordenPaymentInfoTitle {
        font-size: 34px !important;
    }

    .ordenPaymentInfoBox {
        width: 428px !important;
        margin-left: 10px !important;
        height: max-content !important;
    }

    .ordenPaymentInfoBill {
        font-size: 30px !important;
        margin-left: 20px !important;
    }

    .ordenPaymentInfoPaymentMethodBox {
        width: 380px !important;
        margin-left: 30px !important;
        height: 500px !important;
    }


    .ordenPaymentInfoPaymentMethodHeader {
        font-size: 40px !important;
        margin-bottom: 10px !important;
        margin-left: 10px !important;
        text-align: center !important;
    }

    .ordenPaymentInfoButtonTC {
        width: 100% !important;
        padding: 0px, 0px, 0px, 0px !important;
        margin-left: 0px !important;
    }

    .ordenPaymentInfoButtonTD {
        width: 100% !important;
        padding: 0px, 0px, 0px, 0px !important;
        margin-left: 0px !important;

    }

    .ordenPaymentInfoPaymentMethodInfoTC {
        margin-top: -10px !important;
        font-size: 14px !important;
    }

    .ordenPaymentInfoPaymentMethodInfoTCStrong {
        font-size: 14px !important;
    }

    .ordenPaymentInfoPaymentMethodTypeBox {
        height: 263px !important;
        margin-top: 40px !important;

    }

    .ordenPaymentInfoPaymentMethodContinueToPayment {

        margin-top: 10px !important;
        margin-left: 0px !important;
    }

    .ordenPaymentInfoResumeBox {
        height: max-content !important;
        width: 380px !important;
        margin-left: -20px !important;
    }

    .ordenPaymentInfoPriceLessDiscount {
        font-size: 18px !important;
        margin-left: 0px !important;
        margin-top: -33px !important;
    }

    .ordenPaymentInfoResult {
        margin-top: 0px !important;
    }

    .ordenPaymentInfoLine {
        width: 80% !important;
        margin-top: 10px !important;
    }

    .ordenPaymentInfoDiscountInpunt {
        width: 350px !important;
    }

    .discountText {
        margin-bottom: 0px !important;
        font-family: 'Roboto' !important;
        line-height: 40px !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        margin-left: 80px !important;
    }

}