.registerBody {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 98vh !important;

}

.registerCard {
    width: 441px !important;
    height: max-content !important;
    border-radius: 13px !important;
    padding: 40px !important;
    padding-left: 20px !important;
    gap: 32px !important;
    background-color: #FFFFFF !important;
    box-shadow: 0px 2px 6px 0px #19213D24 !important;
}

.registerImage {
    object-fit: cover !important;
    width: 100% !important;
    height: auto !important;
    margin-top: -1rem !important;
    margin-bottom: 2rem !important;
}

.registerTitle {
    width: 366px !important;
    height: 61px !important;
    gap: 24px;
    margin-top: -10px !important;
}

.registerTitleText {
    font-family: 'Roboto' !important;
    font-weight: 600 !important;
    font-size: 32px !important;
    line-height: 32px !important;
    width: "181px" !important;
    height: "23px" !important;
    color: #212C40 !important;
    margin-top: -10px !important;
}

.registerTitleNewUser {
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 17.90px !important;
    line-height: 24px !important;
    width: "366px" !important;
    height: "14px" !important;
}

.registerEmailTextBox {
    width: 385px !important;
    height: 46px !important;
    border-radius: 6px !important;
    border: 1px solid #CED4DA !important;
    padding: 18px, 16px, 18px, 16px !important;
    gap: 10px !important;
    box-shadow: 0px 1px 4px #19213D14 !important;

}

.registerpasswordTextBox {
    width: 385px !important;
    height: 46px !important;
    border-radius: 6px !important;
    border: 1px solid #CED4DA !important;
    padding: 18px, 16px, 18px, 16px !important;
    gap: 10px !important;
    box-shadow: 0px 1px 4px #19213D14 !important;
    margin-bottom: 0px !important;
}

.registershowPassword {
    height: 46px !important;
    background-color: transparent !important;
    color: #212C40 !important;
    border: none !important;
    border-radius: 2px !important;
    border-left: none !important;
    padding: 18px, 16px, 18px, 16px !important;
    gap: 10px !important;
    margin-left: -40px !important;

}

.forgotPassword {
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 18.75px !important;
    color: #212C40 !important;
}

.registerButton {
    width: 100% !important;
    height: 43px !important;
    border-radius: 4px !important;
    padding: 16px, 32px, 16px, 32px !important;
    gap: 10px !important;
    background-color: #19366C !important;
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 18.75px !important;
    color: #FFFFFF !important;
    margin-top: 2rem !important;
    border: none !important;
    border-radius: 4px !important; 
}

.registerButton:hover {
    background-color: #D1432F !important;
    color: #FFFFFF !important;

}