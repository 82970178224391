/* Escritorio extra grande */

@media only screen and (min-width: 1200px) {
    .NavBarImage {
        width: 10rem;
        max-width: 100%;
        height: auto;
        object-fit: contain;
        display: block;
       
    }


    .NavBar {
        border: none !important;
        padding: 2px, 0px, 2px, 0px !important;
        height: 100px !important;
        margin-bottom: 10px !important;

    }


    .NavBarItem {
        width: 100% !important;
        padding-left: 4rem;
        padding-right: 0rem;
        font-size: 1.4rem;
        font-style: 'roboto', sans-serif;
        background-color: #F8F9FA;
        color: #19366C;
        font-weight: 500;
        border: none;
        transition: 0.1s;
    }

    .NavBarItem:hover {

        color: #CC4430;
    }

    .NavBarButtons {
        margin-left: -280px !important;

    }

    .NavInscribeteButton {

        width: 152px !important;
        height: 43px !important;
        border-radius: 4px !important;
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 18.75px !important;
        color: #ffffff !important;
        padding: 16px, 32px, 16px, 32px !important;
        gap: 10px !important;
        background-color: #CC4430 !important;
        margin-top: 8px !important;
        margin-right: 0px !important;
        margin-bottom: 5px !important;

    }

    .NavBarUserIcon {
        margin-top: 0px !important;
        margin-left: 200px !important;
    }

    .NavBarUserButton {

        width: 152px !important;
        height: 43px !important;
        border-radius: 4px !important;
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 18.75px !important;
        color: #ffffff !important;
        padding: 16px, 32px, 16px, 32px !important;
        gap: 10px !important;
        background-color: #CC4430 !important;
        margin-right: 50px !important;
        margin-top: -500px !important;

    }

    .NavBarUserName {
        width: 200px !important;
        margin-top: 0% !important;
        background-color: #ffffff !important;
        color: #000 !important;
        border: none !important;
        font-family: 'Roboto' !important;
        cursor: pointer !important;
    }

    .NavBarUserOptions {
        width: 200px !important;
        margin-top: 0% !important;
        background-color: #ffffff !important;
        color: #000 !important;
        border: none !important;
        font-family: 'Roboto' !important;
        font-size: 16px !important;
        border-top: solid 0.1px !important;
        cursor: pointer !important;
    }

    .NavchangePassWord {

        color: #ffffff !important;
        border: none !important;
        font-family: 'Roboto' !important;
        font-size: 24px !important;
        font-weight: 400 !important;
        line-height: 18.75px !important;
    }

    .NavBodychangePassWord {
        color: #000000 !important;
        border: none !important;
        font-family: 'Roboto' !important;
        font-size: 24px !important;
        font-weight: 400 !important;
        line-height: 18.75px !important;
    }

    .p-dialog-header-icon.p-dialog-header-close.p-link {
        border-color: #ffffff !important;
        color: #ffffff !important;
    }

    .p-dialog-header-icons {
        color: #ffffff !important;
    }


    .logoutButton {
        width: 200px !important;
        height: 3dvh !important;
        border-radius: 0px !important;
        margin-bottom: -3px !important;
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 18.75px !important;
        color: #ffffff !important;
        background-color: #CC4430 !important;
    }
}

/* Escritorio grande */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .NavBarImage {
        width: 10rem;
        max-width: 100%;
        height: auto;
        object-fit: contain;
        display: block;
    }


    .NavBar {
        border: none !important;
        padding: 2px, 0px, 2px, 0px !important;
        height: 100px !important;
        margin-top: 38px !important;
        margin-bottom: 30px !important;

    }

    .NavBarItem {
        width: 100% !important;
        padding-left: 4rem;
        padding-right: 0rem;
        font-size: 1.4rem;
        font-style: 'roboto', sans-serif;
        background-color: #F8F9FA;
        color: #19366C;
        font-weight: 500;
        border: none;
        transition: 0.1s;
    }

    .NavBarItem:hover {

        color: #CC4430;
    }
    .NavBarButtons {
        margin-left: -280px !important;
    }

    .NavInscribeteButton {

        width: 152px !important;
        height: 43px !important;
        border-radius: 4px !important;
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 18.75px !important;
        color: #ffffff !important;
        padding: 16px, 32px, 16px, 32px !important;
        gap: 10px !important;
        background-color: #CC4430 !important;
        margin-right: 50px !important;
        margin-bottom: 5px !important;

    }

    .NavBarUserButton {

        width: 152px !important;
        height: 43px !important;
        border-radius: 4px !important;
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 18.75px !important;
        color: #ffffff !important;
        padding: 16px, 32px, 16px, 32px !important;
        gap: 10px !important;
        background-color: #CC4430 !important;
        margin-right: 0px !important;
        margin-top: -500px !important;
        /* margin-left: 00px !important; */

    }

    .NavBarUserIcon {
        margin-top: 0px !important;
        margin-left: 200px !important;
    }

    .NavBarUserName {
        width: 200px !important;
        margin-top: 0% !important;
        background-color: #ffffff !important;
        color: #000 !important;
        border: none !important;
        font-family: 'Roboto' !important;
        cursor: pointer !important;

    }

    .logoutButton {
        width: 200px !important;
        height: 3dvh !important;
        border-radius: 0px !important;
        margin-bottom: -3px !important;
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 18.75px !important;
        color: #ffffff !important;
        background-color: #CC4430 !important;
    }

}

@media only screen and (min-width: 965px) and (max-width: 984px) {
    .NavBarImage {
        width: 10rem;
        max-width: 100%;
        height: auto;
        object-fit: contain;
        display: block;
    }

    .NavBar {
        border: none !important;
        padding: 2px, 0px, 2px, 0px !important;
        height: 100px !important;
        margin-bottom: 10px !important;
    }

    .NavBarItem {
        width: 100% !important;
        padding-left: 4rem;
        padding-right: 0rem;
        font-size: 1.4rem;
        font-style: 'roboto', sans-serif;
        background-color: #F8F9FA;
        color: #19366C;
        font-weight: 500;
        border: none;
        transition: 0.1s;
    }

    .NavBarItem:hover {

        color: #CC4430;
    }

    .NavBarButtons {
        margin-left: -280px !important;
    }

    .NavInscribeteButton {

        width: 152px !important;
        height: 43px !important;
        border-radius: 4px !important;
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 18.75px !important;
        color: #ffffff !important;
        padding: 16px, 32px, 16px, 32px !important;
        gap: 10px !important;
        background-color: #CC4430 !important;
        margin-top: 8px !important;
        margin-right: 0px !important;
        margin-bottom: 5px !important;

    }

    .NavBarUserIcon {
        margin-top: 0px !important;
        margin-left: 200px !important;
    }

    .NavBarUserButton {

        width: 152px !important;
        height: 43px !important;
        border-radius: 4px !important;
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 18.75px !important;
        color: #ffffff !important;
        padding: 16px, 32px, 16px, 32px !important;
        gap: 10px !important;
        background-color: #CC4430 !important;
        margin-right: 50px !important;
        margin-top: -500px !important;

    }

    .NavBarUserName {
        width: 200px !important;
        margin-top: 0% !important;
        background-color: #ffffff !important;
        color: #000 !important;
        border: none !important;
        font-family: 'Roboto' !important;
        cursor: pointer !important;
    }

    .NavBarUserOptions {
        width: 200px !important;
        margin-top: 0% !important;
        background-color: #ffffff !important;
        color: #000 !important;
        border: none !important;
        font-family: 'Roboto' !important;
        font-size: 16px !important;
        border-top: solid 0.1px !important;
        cursor: pointer !important;
    }

    .NavchangePassWord {

        color: #ffffff !important;
        border: none !important;
        font-family: 'Roboto' !important;
        font-size: 24px !important;
        font-weight: 400 !important;
        line-height: 18.75px !important;
    }

    .NavBodychangePassWord {
        color: #000000 !important;
        border: none !important;
        font-family: 'Roboto' !important;
        font-size: 24px !important;
        font-weight: 400 !important;
        line-height: 18.75px !important;
    }

    .p-dialog-header-icon.p-dialog-header-close.p-link {
        border-color: #ffffff !important;
        color: #ffffff !important;
    }

    .p-dialog-header-icons {
        color: #ffffff !important;
    }


    .logoutButton {
        width: 200px !important;
        height: 3dvh !important;
        border-radius: 0px !important;
        margin-bottom: -3px !important;
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 18.75px !important;
        color: #ffffff !important;
        background-color: #CC4430 !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 964px) {
    .p-menubar-button {
        font-size: 26px !important;
        color: #000 !important;
        margin-left: 500px !important;
        z-index: 100 !important;
    }

    .pi-bars {
        font-size: 26px !important;
        color: #000 !important;
        width: 100px !important;
    }

    .p-menubar-root-list {
        width: 400px !important;
        margin-left: 400px !important;
        height: 270px !important;
    }

    .NavBarImage {
        width: 10rem;
        max-width: 100%;
        height: auto;
        object-fit: contain;
        display: block;
    }

    .NavBar {
        border: none !important;
        padding: 2rem, 0px, 2rem, 0px !important;
        height: 100px !important;
        margin-bottom: 10px !important;
    }

    .NavBarItem {
        width: 100% !important;
        padding-left: 4rem;
        padding-right: 0rem;
        font-size: 1.4rem;
        font-style: 'roboto', sans-serif;
        background-color: #F8F9FA;
        color: #19366C;
        font-weight: 500;
        border: none;
        transition: 0.1s;
    }

    .NavBarItem:hover {

        color: #CC4430;
    }

    .NavBarButtons {
        margin-left: 0px !important;
    }

    .NavInscribeteButton {

        width: 152px !important;
        height: 43px !important;
        border-radius: 4px !important;
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 18.75px !important;
        color: #ffffff !important;
        padding: 16px, 32px, 16px, 32px !important;
        gap: 10px !important;
        background-color: #CC4430 !important;
        margin-top: 8px !important;
        margin-right: 0px !important;
        margin-bottom: 5px !important;

    }

    .NavBarUserIcon {
        margin-top: 0px !important;
        margin-left: 0px !important;
    }

    .NavBarUserButton {

        width: 152px !important;
        height: 43px !important;
        border-radius: 4px !important;
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 18.75px !important;
        color: #ffffff !important;
        padding: 16px, 32px, 16px, 32px !important;
        gap: 10px !important;
        background-color: #CC4430 !important;
        margin-right: 50px !important;
        margin-top: -500px !important;

    }

    .NavBarUserName {
        width: 200px !important;
        margin-top: 0% !important;
        background-color: #ffffff !important;
        color: #000 !important;
        border: none !important;
        font-family: 'Roboto' !important;
        cursor: pointer !important;
    }

    .NavBarUserOptions {
        width: 200px !important;
        margin-top: 0% !important;
        background-color: #ffffff !important;
        color: #000 !important;
        border: none !important;
        font-family: 'Roboto' !important;
        font-size: 16px !important;
        border-top: solid 0.1px !important;
        cursor: pointer !important;
    }

    .NavchangePassWord {

        color: #ffffff !important;
        border: none !important;
        font-family: 'Roboto' !important;
        font-size: 24px !important;
        font-weight: 400 !important;
        line-height: 18.75px !important;
    }

    .NavBodychangePassWord {
        color: #000000 !important;
        border: none !important;
        font-family: 'Roboto' !important;
        font-size: 24px !important;
        font-weight: 400 !important;
        line-height: 18.75px !important;
    }

    .p-dialog-header-icon.p-dialog-header-close.p-link {
        border-color: #ffffff !important;
        color: #ffffff !important;
    }

    .p-dialog-header-icons {
        color: #ffffff !important;
    }


    .logoutButton {
        width: 200px !important;
        height: 3dvh !important;
        border-radius: 0px !important;
        margin-bottom: -3px !important;
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 18.75px !important;
        color: #ffffff !important;
        background-color: #CC4430 !important;
    }
}

/* Teléfonos */
@media only screen and (max-width: 430px) {

    .NavBarImage {
        width: 10rem;
        max-width: 100%;
        height: auto;
        object-fit: contain;
        display: block;
    }

    .p-menubar-button {
        font-size: 26px !important;
        color: #000 !important;
        margin-left: 70px !important;
        z-index: 100 !important;
    }

    .pi-bars {
        font-size: 26px !important;
        color: #000 !important;
        width: 100px !important;
    }

    .p-menubar-root-list {
        width: 400px !important;
        margin-left: 0px !important;
        height: 270px !important;
    }


    .NavBar {
        border: none !important;
        padding: 2px, 0px, 2px, 0px !important;
        height: 100px !important;

    }

    .NavBarUserIcon {
        margin-top: 0px !important;
        margin-left: 250px !important;
    }

    .NavBarItem {
        width: 100% !important;
        padding-left: 4rem;
        padding-right: 0rem;
        font-size: 1.4rem;
        font-style: 'roboto', sans-serif;
        background-color: #F8F9FA;
        color: #19366C;
        font-weight: 500;
        border: none;
        transition: 0.1s;
    }

    .NavBarItem:hover {

        color: #CC4430;
    }
    .NavBarButtons {
        margin-left: -280px !important;

    }

    .NavInscribeteButton {

        height: 19px !important;
        font-family: 'Roboto' !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 18.75px !important;
        color: #ffffff !important;
        gap: 10px !important;
        background-color: #CC4430 !important;
        margin-left: 12px !important;
        margin-bottom: 10px !important;
        margin-top: 10px !important;
        padding-bottom: 25px !important;
        padding-top: 25px !important;
        box-shadow: 0px 2px 6px #19213D !important;
        width: 90% !important;
    }

    .NavBarUserButton {

        width: 152px !important;
        height: 43px !important;
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 18.75px !important;
        border-radius: 4px !important;
        color: #ffffff !important;
        padding: 16px, 32px, 16px, 32px !important;
        gap: 10px !important;
        background-color: #CC4430 !important;
        margin-right: 50px !important;
        margin-top: -500px !important;

    }

    #popup_menu_left {
        width: 300px !important;
        text-align: center !important;
        margin-top: 10px !important;
    }

    .NavBarUserName {
        width: 200px !important;
        margin-top: 0% !important;
        font-size: 20px !important;
        background-color: #ffffff !important;
        color: #000 !important;
        border: none !important;
        font-family: 'Roboto' !important;
        cursor: pointer !important;
    }

    .NavBarUserOptions {
        width: 300px !important;
        margin-top: 0% !important;
        background-color: #ffffff !important;
        color: #000 !important;
        border: none !important;
        font-family: 'Roboto' !important;
        font-size: 24px !important;
        border-top: solid 0.1px !important;
        cursor: pointer !important;
    }

    .logoutButton {
        width: 300px !important;
        height: 8dvh !important;
        border-radius: 0px !important;
        margin-bottom: -3px !important;
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 20px !important;
        line-height: 18.75px !important;
        color: #ffffff !important;
        background-color: #CC4430 !important;
    }

    .pi.pi-fw.pi-power-off {
        font-size: 20px !important;
    }

    .NavchangePassWord {

        color: #ffffff !important;
        border: none !important;
        font-family: 'Roboto' !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 18.75px !important;
    }

    .NavBodychangePassWord {
        color: #000000 !important;
        border: none !important;
        font-family: 'Roboto' !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 18.75px !important;
    }

    .p-dialog-header-icon.p-dialog-header-close.p-link {
        border-color: #ffffff !important;
        color: #ffffff !important;
    }

    .p-dialog-header-icons {
        color: #ffffff !important;
    }
}