.ordenDeclinedBox {
    margin-top: 50px !important;
    width: 993px !important;
    height: 580px !important;
    top: 242px !important;
    left: 224px !important;
    padding: 60px 0px 0px 0px !important;
    gap: 58px !important;
    border-radius: 13px 0px 0px 0px !important;
    
    background-color: #FFFFFF !important;
}

.ordenDeclinedTitle {
    gap: 0px !important;
    
    font-family: 'Roboto' !important;
    font-size: 34px !important;
    font-weight: 600 !important;
    line-height: 48px !important;
    text-align: center !important;
    color: #212C40 !important;
}

.ordenDeclinedSubtitle {
    height: 23px !important;
    gap: 0px !important;
    
    font-family: 'Roboto' !important;
    font-size: 26px !important;
    font-weight: 500 !important;
    line-height: 32px !important;
    text-align: center !important;
    margin-top: 20px !important;


}



.ordenDeclinedButtonCheck {
    width: 10% !important;
    height: 90px !important;
    margin-top: -100px !important;
    margin-left: 440px !important;
    background-color: #F78F8F !important;
    font-size: xx-large !important;
    border: 2px solid red !important;
}

.ordenDeclinedButtonCheck .p-button-icon {
    font-size: 50px !important;
}


.ordenDeclinedButtonPay {
    width: 309px !important;
    height: 43px !important;
    padding: 16px 32px 16px 32px !important;
    gap: 10px !important;
    border-radius: 4px 0px 0px 0px !important;
    
    background-color: #19366C;
    margin-top: 90px !important;
}



/* Escritorio grande */
@media only screen and (min-width: 1800px) {}

/* Escritorio medio */
@media only screen and (min-width: 992px) and (max-width: 1199px) {}

@media only screen and (min-width: 965px) and (max-width: 984px) {}

@media only screen and (min-width: 768px) and (max-width: 964px) {}

/* Teléfonos */
@media only screen and (min-width: 521px) and (max-width: 767px) {}

/* Teléfonos */
@media only screen and (max-width: 430px) {
    .ordenDeclinedBox {
        width: 385px !important;
        height: 650px !important;
        margin-top: 0px !important; 
    }
    .ordenDeclinedButtonCheck {
        width: 90px!important;
        height: 90px !important;
        margin-left: 140px !important;
        
    }
    .ordenDeclinedSubtitle {
        font-size: 20px !important;
        text-align: center !important;

    }

    .ordenDeclinedMessage {
        margin-top: 0px !important;
        margin-left: 0px !important;
        width: 100% !important;
    }
    .ordenDeclinedButtonPay {
        margin-top: 150px !important;
        margin-left: 10px !important;
    }
}