
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  [class="p-dialog-header-icon p-dialog-header-close p-link"]{
    display: none;
  }
 
 .loadingDialog .p-dialog-mask {
    background: transparent !important;
  }
  .loadingDialog .p-dialog {
    background: transparent !important;
    box-shadow: none !important;
  }