.checkCircleIcon
{
    color: green;
    scale: 10;
    padding: 8%;
}

.button
{
    background-color: #1f3564;
    color: white;
    font-size: 1.2vw;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    border-radius: 8px;
    height: 3vw;
    padding: 1% 5%;
    margin-top: 1%;
    cursor: pointer !important;
    border: none;
    box-shadow: none !important;
}

.button:hover
{
    background-color: #d8544f;
    font-weight: bold;
    transition: 0.6s;
}

.cardMessage{
    border-radius: 20px;
    border: none;
    box-shadow: 0px 0px 0px 0px #d3d3d3;
    overflow: hidden;
    align-self: center;
    margin-top: -10rem;
    height: max-content;
}

.cardMessage p{
    font-family: "Roboto", sans-serif;
    align-self: center;
    white-space: none;
}

@media (max-width: 430px) {
    .checkCircleIcon{
        margin-bottom: 3rem;
        margin-top: 3rem;
    }
    
    .button
    {
        font-size: 5vw;
        height: 10vw;
        border-radius: 15px;
        padding: 5% 15%;
    }

    .cardMessage{
        margin-top: 7rem !important;
        height: max-content;
        width: 100%;
    }
  }