.emailConfirmationBody {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 98vh !important;
}

.emailConfirmationCard {
    width: 441px !important;
    height: 450px !important;
    border-radius: 13px !important;
    padding: 40px !important;
    padding-left: 20px !important;
    gap: 32px !important;
    background-color: #FFFFFF !important;
    box-shadow: 0px 2px 6px 0px #19213D24 !important;
}

.emailConfirmationImage {
    object-fit: fill !important;
    width: 380px !important;
    height: 156px !important;
    margin-top: -30px !important;
    margin-bottom: 40px !important;
}

.emailConfirmationTitle {
    width: 366px !important;
    height: 61px !important;
    gap: 24px;
    color: black!important;
    padding-left: 70px!important;
}

.emailConfirmationTitleText {
    font-family: 'Roboto'!important;
    font-weight: 600!important;
    font-size: 28px!important;
    line-height: 32px!important;
    width: 181px!important;
    height: 23px!important;
    color: #212C40!important;
    margin-left: 10px!important;

}

.emailConfirmationButton {
    width: 366px !important;
    height: 43px !important;
    border-radius: 4px !important;
    padding: 16px, 32px, 16px, 32px !important;
    gap: 10px !important;
    background-color: #19366C !important;
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 18.75px !important;
    color: #FFFFFF !important;

}