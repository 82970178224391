.ordenApprovedBox {
    margin-top: 50px !important;
    width: 993px !important;
    height: 550px !important;
    top: 242px !important;
    left: 224px !important;
    padding: 60px 0px 0px 0px !important;
    gap: 58px !important;
    border-radius: 13px 0px 0px 0px !important;
    
    background-color: #FFFFFF !important;
    /* margin-left: 280px !important; */

}

.ordenApprovedTitle {
    gap: 0px !important;
    
    font-family: 'Roboto' !important;
    font-size: 34px !important;
    font-weight: 600 !important;
    line-height: 48px !important;
    text-align: center !important;
    color: #212C40 !important;
}

.ordenApprovedSubtitle {
    height: 23px !important;
    gap: 0px !important;
    
    font-family: 'Roboto' !important;
    font-size: 26px !important;
    font-weight: 500 !important;
    line-height: 32px !important;
    text-align: center !important;
    margin-top: 20px !important;


}

.ordenApprovedButtonCheck {
    width: 10% !important;
    height: 90px !important;
    margin-top: -100px !important;
    margin-left: 440px !important;
    background-color: #B9E0BD !important;
    font-size: xx-large !important;
}

.ordenApprovedButtonCheck .p-button-icon {
    font-size: 50px !important;
}


.ordenApprovedButtonPay {
    width: 309px !important;
    height: 43px !important;
    padding: 16px 32px 16px 32px !important;
    gap: 10px !important;
    border-radius: 4px 0px 0px 0px !important;
    
    background-color: #19366C !important;
    margin-top: 50px !important;
}



/* Escritorio grande */
@media only screen and (min-width: 1800px) {}

/* Escritorio medio */
@media only screen and (min-width: 992px) and (max-width: 1199px) {}

@media only screen and (min-width: 965px) and (max-width: 984px) {}

@media only screen and (min-width: 768px) and (max-width: 964px) {}

/* Teléfonos */
@media only screen and (min-width: 521px) and (max-width: 767px) {}

/* Teléfonos */
@media only screen and (max-width: 430px) {
    .ordenApprovedBox {
        width: 385px !important;
        height: 650px !important;
        margin-top: 0px !important; 

    }

    .ordenApprovedTitle {
        font-size: 24px !important;
        font-weight: 800 !important;
        line-height: 48px !important;

    }

    .ordenApprovedButtonCheck {
        width: 90px !important;
        height: 90px !important;
        margin-left: 140px !important;
    }

    .ordenApprovedSubtitle {
        font-size: 22px !important;
        margin-top: 10px !important;
    }



    .ordenApprovedButtonCheck .p-button-icon {
        font-size: 50px !important;
    }

    .buttonPay {
        width: 309px;
        height: 43px;
        padding: 16px 32px 16px 32px;
        gap: 10px;
        border-radius: 4px 0px 0px 0px;
        
        background-color: #19366C;

    }
}