.warningDialog {
    border: none !important;
    box-shadow: 0px 0px 0px 0px #d3d3d3 !important;
    height: max-content !important;

}

.warningDialog .p-dialog-content {
    padding: 2rem;
    background-color: #CED4DA !important;
}
.warningDialog .p-dialog	 {
    padding: 3rem;
    background-color: #CED4DA !important;
}